export var ratingMap = [
    { value: 1, label: "OK" },
    { value: 2, label: "Good" },
    { value: 3, label: "Great" },
    { value: 4, label: "Fabulous!" },
    { value: 5, label: "Exceptional!" },
];
export var tooltipMap = [
    {
        title: "OK",
        description: "The venue was not able to offer any safe options for my allergies and/or I could not eat here because I did not feel safe. This rate also includes “near miss” situations."
    },
    {
        title: "Good",
        description: "I had a safe meal and was able to find suitable options, and the restaurant ticked all the boxes from an allergen management perspective."
    },
    {
        title: "Great",
        description: "The restaurant staff and management were very attentive, and were able to suggest suitable options and alternatives."
    },
    {
        title: "Fabulous",
        description: "From the moment I walked through the door, everybody made me feel welcome and tried their very best to make my experience stress-free and unforgettable."
    },
    {
        title: "Exceptional",
        description: "I love this place, I cannot recommend it enough. The staff, chef and management did everything they could do to make this an amazing experience, and were able to offer some incredible dishes irrespective of my dietary needs. Well worth a visit."
    }
];
