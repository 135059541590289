var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Lib
import React, { lazy, Suspense } from "react";
// Components
import Summary from "./Summary";
var VenueOverview = lazy(function () { return import('../VenueOverview'); });
import { venueFallback } from "../fallbacks/venueFallback";
var AddYourVenueSummary = /** @class */ (function (_super) {
    __extends(AddYourVenueSummary, _super);
    function AddYourVenueSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.changeToEdit = function () {
            _this.props.onEdit();
        };
        _this.mergeTaxLevels = function (tax) {
            return _this.concatTaxonomies(__spreadArray([tax.primary], tax.secondary, true));
        };
        _this.concatTaxonomies = function (cats) {
            if (!cats.length)
                return "";
            var labels = cats.map(function (tax) { return tax.label; });
            return labels.join(', ');
        };
        _this.render = function () {
            var _a;
            var summaryHeader = _this.getHeader();
            var venueSkeleton = venueFallback();
            return (React.createElement("section", { className: "summary" },
                summaryHeader,
                React.createElement("h3", { className: "h2" }, "Venue Information"),
                React.createElement(Suspense, { fallback: venueSkeleton }, _this.props.venue !== null && (React.createElement(VenueOverview, { venue: _this.props.venue, hideShadow: true }))),
                React.createElement("dl", { className: "summary__list data-list" },
                    _this.props.values.venue.description && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Venue Description"),
                        React.createElement("dd", null, _this.props.values.venue.description))),
                    _this.props.values.venueType.length && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Venue Type"),
                        React.createElement("dd", null, _this.concatTaxonomies(_this.props.values.venueType)))),
                    _this.props.values.venueFeature.length && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Features"),
                        React.createElement("dd", null, _this.concatTaxonomies(_this.props.values.venueFeature)))),
                    _this.props.values.mealType.length && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Meal Types"),
                        React.createElement("dd", null, _this.concatTaxonomies(_this.props.values.mealType)))),
                    ((_a = _this.props.values.cuisineType) === null || _a === void 0 ? void 0 : _a.primary) && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Cuisine"),
                        React.createElement("dd", null, _this.mergeTaxLevels(_this.props.values.cuisineType)))),
                    _this.props.values.kitchenFreeFrom.length && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Kitchen free-from"),
                        React.createElement("dd", null, _this.concatTaxonomies(_this.props.values.kitchenFreeFrom)))),
                    _this.props.values.menuFreeFrom.length && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Meal free-from"),
                        React.createElement("dd", null, _this.concatTaxonomies(_this.props.values.menuFreeFrom)))))));
        };
        return _this;
    }
    return AddYourVenueSummary;
}(Summary));
export default AddYourVenueSummary;
